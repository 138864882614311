<style scoped>
.base {
  background-color: rgb(246, 248, 255);
  text-align: center;

}

.logo {
  width: 100%;
  height: calc(100vw * 451.5 / 750);
}

.logo > img {
  /*width: 101%;*/
}

.button-area {
  height: 52px;
  background: #FFFFFF;
  /*opacity: 0.5;*/
  margin-top: 1px;
  margin-bottom: 1px;
}

.button-base {
  display: inline-block;
  width: 30px;
  height: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  /*color: #F1BD20;*/
  line-height: 15px;
  opacity: 0.8;
  font-size: 14px;
  margin-top: 20px;

  text-align: center;

}

.today-income {
  width: 100vw;
  height: 15px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  opacity: 0.8;
  margin-left: 8px;
  margin-top: calc(44px - 100vw * 452 / 750);
  margin-bottom: 32px;
}

.income-text {
  width: 100vw;
  height: 34px;
  font-size: 45px;
  letter-spacing: 2px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #E9BE3E;
  line-height: 45px;
}

.button-onclick {
  border-bottom: 2px solid rgba(32, 64, 189, 1);
  color: rgba(32, 64, 189, 1);
}

.task-area {
  margin-top: 3px;
  padding-top: 2px;
  background-color: white;
  overflow: hidden;
}

.task-box {
  margin: 15px 7px 0px 7px;
  padding-left: 8px;
  border-radius: 4px;
  height: 78px;
  /*border-bottom: 1px solid rgba(153, 153, 153, 0.1);*/
  display: flex;
  background-color: #EBEBEB;
}

.task-context {
  width: 70%;
  height: 70px;
}

.img-style {
  width: 15px;
  height: 15px;
  float: left;
  margin-top: 12px;

}

.word-area {
  height: 50px;
  margin: 0 0 0 20px;
  /*padding: 20px 15px 20px 15px;*/
}

.wordsize {
  /*width: 59%;*/
  text-align: left;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 15px;
  margin-top: 8px;
  word-break: break-all;
  white-space: normal;
  line-height: 21px;
}

.wordsize2 {
  /*width: 65%;*/
  text-align: left;
  margin-left: -20px;
  margin-top: 8px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: rgba(153, 153, 153, 0.8);
  /*margin: 8px 0 0 0;*/
  line-height: 18px;
}

.task-button-area {
  /*display: in;*/
  /*height: 30px;*/
  margin: 0;
  padding: 0;
  width: 40%;
  font-size: 32px;
  word-break: break-all;
  /*overflow : hidden;*/
  /*text-overflow: ellipsis;*/
}

.button-icon {
  height: 18px;
  margin-right: 8px;
  margin-top: -4px;
}


/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

/deep/ .el-scrollbar__thumb {
  display: none;
}

.text-description {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.199);
  z-index: 2000;
}

.model-button {
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 1999;
  border: none;
  padding: 0;
}

.collapse-header-span {
  color: #ADADAD;
  font-size: 14px;
}

.collapse-footer-span {
  height: 50px;
  padding-top: 18px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 12px;
  opacity: 0.6;
}

/deep/ .el-collapse-item__header {
  height: 80px;

}

/deep/ .el-collapse-item__arrow {
  margin-top: -20px;
  margin-right: 15px;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0px;
}

</style>

<template>
  <div class="base" v-loading="fullscreenLoading">

    <el-row class="button-area">
      <el-col :span="11">
        <img src="@/assets/image/tradelist/icon_sort.png" class="button-icon">
        <span :class="{
          'button-base':true,
          }" @click="showQR">
          排序
          </span>
      </el-col>
      <el-col :span="1">
        <p style="width: 1px;height: 25px;margin-top: 14px;background-color: white;margin-left: 10px;">
        </p>
        <!--        <el-divider  direction="vertical" content-position="center"></el-divider>-->
      </el-col>
      <el-col :span="11">
        <img src="@/assets/image/tradelist/icon_filter.png" class="button-icon">
        <span :class="{
          'button-base':true,
          }" @click="showHistoryPicker">
          筛选
          </span>
      </el-col>
    </el-row>
    <el-collapse accordion style="padding-top: 1px;text-align: center;" v-model="activeNames">
      <el-collapse-item v-for='(item,index) of datalist' :key="item['date']" :name="item['date']"
                        @click.native="changeCollapse(item['date'],index)">

        <template slot="title">
          <div
              style="width: 80%;height: 80px;text-align: left;padding-left: 15px;line-height: 40px;">
            {{ item['dateString'] }}
            <div style="margin-bottom: 15px;">
              <span class="collapse-header-span">交易笔数:</span>&emsp;{{ item['amount'] }}
              <span class="collapse-header-span" style="margin-left: 80px;">&emsp;营业额:</span>
              {{ (parseFloat(item['total']) / 100.0).toFixed(2) }}
            </div>
          </div>

        </template>

        <el-row style="background-color: #F6F6F6;">
          <el-col :xs="24" :sm="12" v-for="subitem of item.list" :key="subitem['orderNo']" @click.native="showDetail(subitem)">
            <div class="task-box">
              <div class="task-context">
                <img :src="imgurl[subitem['type']]" class="img-style">
                <div class="word-area">
                  <div class="wordsize">{{ subitem['date'] }}</div>
                  <div class="wordsize2">收款方: {{ merName }}</div>
                </div>
              </div>
              <div class="task-button-area" :style="{'color':'#333333'}">
                +{{ (parseFloat(subitem['entryAmt']) / 100.0).toFixed(2) }}
              </div>
            </div>
          </el-col>
          <el-col class="collapse-footer-span"><span>以上是该日的全部交易记录</span></el-col>
        </el-row>
      </el-collapse-item>

    </el-collapse>

    <calendar :show.sync="datepicker_is_visable" mode="during" @change="onChange" :maxDate="maxDate"/>
    <!--        <span style="font-size: 8px;line-height: 10px; color: #EBEBEB; height: 50px;padding-bottom: 20px;">{{dateduration}}</span>-->

  </div>
</template>
<script>

import Calendar from 'vue-mobile-calendar';
// import dayjs from "dayjs";

export default {
  name: "smer_tradelist",
  components: {
    inlineCalendar: Calendar.inlineCalendar,
    calendar: Calendar.calendar
  },
  data() {
    return {
      datepicker_is_visable: false,
      income_text: "",
      income_value: "",
      isAsc:false,
      listHeight: "",
      maxDate: "",
      isPullingUp: false,
      fullscreenLoading: false,
      bsScroll: "",
      merName: "",
      activeNames: "",
      start_date: "",
      end_date: "",
      dateduration: "",
      logo: {
        "icon": require("../../assets/image/pay/icon_merchant.png")
      },
      pageend: false,
      loading: false,
      status: "0",
      id: "1",
      imgurl: {
        "1": require("../../assets/image/home/icon_type_alipay.png"),
        "2": require("../../assets/image/home/icon_type_wx.png"),
        "3": require("../../assets/image/home/icon_type_boa.png"),
      },
      datalist: [
        // {
        //   "id": "1",
        //   "type": 1,
        //   "title": "2022.01.05 11:22:42",
        //   "date": "2022.01.05",
        //   "orderNo": "灵岩山寺",
        //   "entryAmt": 100,
        //
        // },
        // {
        //   "id": "2",
        //   "type": 1,
        //   "title": "2022.01.05 11:22:42",
        //   "date": "2022.01.05",
        //   "orderNo": "灵岩山寺",
        //   "entryAmt": 100,
        //
        // },
      ],


    }
  },
  methods: {
    //
    showDetail(item){
      // console.log(item)
      this.$router.push({path:"/h5/tradedetail",query:item})
    },
    changeCollapse(name, index) {
      // console.log(name)
      // console.log(this.activeNames)
      if (this.activeNames.length > 0) {
        this.requestDailyList(name, index)
      }
    },
    onChange(date) {
      let start_d = null
      let end_d = null;
      // console.log(date)
      if (date.length == 2) {
        start_d = date[0].format("YYYY-MM-DD")
        end_d = date[1].format("YYYY-MM-DD")
      }
      if (start_d && end_d) {
        this.start_date = start_d;
        this.end_date = end_d;
        this.dateduration = " " + start_d + "~" + end_d;
        this.refreshData()

      }
      // console.log(start_d)
      // console.log(end_d)
    },
    showQR() {
      this.isAsc = !this.isAsc;
      this.refreshData()
    },
    closeQR() {

    },
    showHistoryPicker() {
      this.datepicker_is_visable = true;

    },
    getContentHeight() {
      let windowheight = document.documentElement.clientHeight
      let taskheight = windowheight - 55
      this.listHeight = taskheight.toString() + "px"
      // this.bsScroll.refresh()
    },
    backtop() {
      this.bsScroll.scrollTo(0, 0, 300)
    },
    init_bsscroll() {


    },
    scrollHandler(pos) {
      if (pos.y < -1000) {
        this.backtopVisible = true
      } else {
        this.backtopVisible = false
      }

    },
    async pullingDownHandler() {

    },
    async finishPullDown() {
      // console.log('finish pullDown')
      // this.bsScroll.finishPullDown()
      setTimeout(() => {
        this.bsScroll.refresh()
      }, 100)
    },
    async pullingUpHandler() {
      if (!this.pageend) {
        this.isPullingUp = true
        this.page += 1
        this.refreshData()
        // console.log("pullingUP")
      }
    },
    refreshData() {
      this.merName = localStorage.getItem('merchantName')
      let _this = this;
      let params = {
        "startDate": this.start_date,
        "endDate": this.end_date,
        "isAsc":this.isAsc,
        "merchantId":this.merchantId,
      }
      Http.post('/frontend/order/myMerchantHistoryStatistic', params).then(result => {
        if (result.code === 200) {
          // console.log(result)
          _this.datalist = []
          _this.processList(result.data)
          _this.$forceUpdate()
          // _this.income_value = result.data.amount;
          // _this.income_text = _this.income_is_visable ? (_this.income_value / 100.0).toFixed(2).toString() : "****"

        } else {
          _this.$message.error(result.message || "查询失败！")
        }
      }).catch(error => {
        // console.log(error)
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    requestDailyList(date, idx) {
      // console.log(idx)
      this.merName = localStorage.getItem('merchantName')
      let _this = this;
      let params = {
        "date": date,
        "merchantId":this.merchantId,
      }
      let item = ""
      if (idx >= 0 && idx < this.datalist.length) {
        item = this.datalist[idx]
      } else {
        // console.log("error")
      }
      _this.fullscreenLoading = true
      Http.post('/frontend/order/myMerchantDailyStatisticDetail', params).then(result => {
        if (result.code === 200) {
          // console.log(result);
          if (result.data) {
            item['list'] = result.data

            //  new Date(item['date']).pattern("yyyy年MM月dd日")
            _this.processDetailList(item['list'])
            _this.datalist[idx] = item;
            // console.log(_this.datalist)
          }
          _this.$forceUpdate()

        } else {
          _this.$message.error(result.message || "查询失败！")
        }
        _this.fullscreenLoading = false
      }).catch(error => {
        // console.log(error)
        _this.fullscreenLoading = false
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
        // console.log(item)

      })
    },
    processList(infoList) {
      let i = 0
      for (let item of infoList) {
        if (item['date']) {
          item['list'] = []
          let dt = new Date(item['date']);
          // console.log(dt)
          item['dateString'] = dt.getFullYear().toString() + "年" + (dt.getMonth() + 1).toString() + "月" + dt.getDate().toString() + "日";
        }
        this.datalist.push(item)
      }

    },
    processDetailList(infoList) {
      for (let item of infoList) {
        // console.log(item)
        if (item["payType"] == "EP138" || item["payType"] == "EP139" || item["payType"] == "EP140") {
          item["type"] = "2"
        } else if (item["payType"] == "EP159" || item["payType"] == "EP160" || item["payType"] == "EP161") {
          item["type"] = "1"
        } else {
          item["type"] = "3"
        }
      }
    },
  },
  mounted() {
    this.maxDate = new Date().toDateString();
    this.merchantId = this.$route.query.merchantId;
    if (!this.merchantId){
      this.$message.error("无法获取数据，缺少必要的参数");
    }
    this.$nextTick(() => {
      this.init_bsscroll()
    })
    this.getContentHeight()
    this.merName = localStorage.getItem('merchantName')
    this.refreshData()

  },
  created() {
    document.title = "交易记录"
  },
  computed: {

    isShowQR() {
      return this.$store.state.showQR;
    },

  },
}
</script>